html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

/*****************************
Theme Basics Settings
******************************/

@font-face {font-family: "SBLiquidW01-Rounded";
  src: url("../../fonts/ab92deea72adeb001d988f0612e8f280.eot"); /* IE9*/
  src: url("../../fonts/ab92deea72adeb001d988f0612e8f280.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("../../fonts/ab92deea72adeb001d988f0612e8f280.woff2") format("woff2"), /* chrome、firefox */
  url("../../fonts/ab92deea72adeb001d988f0612e8f280.woff") format("woff"), /* chrome、firefox */
  url("../../fonts/ab92deea72adeb001d988f0612e8f280.ttf") format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
  url("../../fonts/ab92deea72adeb001d988f0612e8f280.svg#SB Liquid W01 Rounded") format("svg"); /* iOS 4.1- */
}


.section-inner { 
	margin: 0 auto;
}

.w-w2 {	
	@include media($large-screen-up) {
		min-width: 970px;
	}
}

.skip{
	 @include elementInvisible();
}


#wrapper{
	position: relative;
	min-width: 100%;
}

html{
	
}

body{
	background-color: #fff;
	background-repeat: no-repeat;
	background-position: left top;
	background-size: auto;
	background-attachment: fixed;

	&.home, &.contact, &.work-at-abc{
		background-size: cover;
		background-position: 50% 50%;
	}
}

/*******************************************
Header
********************************************/

#header {
	@include position(absolute, 0 auto auto 50px);
	background: url(../img/nav-bg.png) no-repeat center bottom;
	width: 308px;
	z-index: 50;
	padding-top: 10px;
}

.h-w1{
	position: relative;
	height: 100%;
	padding-bottom: 100px;

	&:before,
	&:after{
		content:"";
		position: absolute;
		bottom: 38px;
		width: 92px;
		height: 10px;
		background: url(../img/nav-pattern-bg.png) repeat;
	}
		&:before{
			left: 15px;
		}
		&:after{
			right: 15px;
		}
}


@keyframes swing {
  20% {
    transform: rotate3d(0, 0, 1, 15deg);
  }

  40% {
    transform: rotate3d(0, 0, 1, -10deg);
  }

  60% {
    transform: rotate3d(0, 0, 1, 5deg);
  }

  80% {
    transform: rotate3d(0, 0, 1, -5deg);
  }

  to {
    transform: rotate3d(0, 0, 1, 0deg);
  }
}

#logo {
	@include position(absolute, auto auto 20px 50%);
	display: block;
	width: 64px;
	margin-left: -32px;
	animation-duration: 1s;
	animation-fill-mode: both;

	&:hover{
		transform-origin: top center;
		animation-name: swing;
		animation-iteration-count: 1;
	}
}

#menu-toggle{
	display: none;
	position: absolute;
	right:20px;
	top:33px;
	height:30px;
	line-height: 30px;
	padding-left:40px;
	width:1px;/*set to 1 to hide text*/
	overflow: hidden;
	font-size:15px;
	color:#fff;
	text-transform:uppercase;
	text-decoration:none;
	@include media($large-screen-up) {
		display:none;
	}
	&.open{
		width:1px;
		font-size:0px;
	}


	&:after, & span:before, & span:after {
		border-radius: 1px;
		height: 3px; 
		width: 25px;
		background: #000;
		display: block;
		content: '';
		position: absolute;
		right:7px;
		cursor: pointer;		
	}
	//top bar
	& span:before {
		top: 5px;
	}
	//middle bar
	& span:after {
		top: 12px;
	}
	//bottom bar
	&:after{
		top:19px;
	} 
	 
	& span:before, & span:after {
		transition: all 500ms ease-in;
	}
		&.open:after {
			transform: translate(-999px, 0)
		}
	
	&.open span:before {
		transform: translate(0, 7px) rotate(45deg);
	}
	&.open span:after {
		transform: translate(0, 0px) rotate(-45deg);
	}
}

#mainnav {
	position: relative;
	padding: 16px 30px;

	&:after{
		content:"";
		@include position(absolute, auto 20px 0 20px);
		height: 1px;
		border-bottom: 2px dashed #000;
	}

	&>ul{
		margin-left: -4px;
		margin-right: -4px;
	}
	&>ul>li{
		display: inline-block;
		margin: 2px 4px;
	}
	&>ul>li>a{
		color: #000;
		text-transform: uppercase;
		font-size: 16px;
		font-family: "SBLiquidW01-Rounded", Helvetica, sans-serif;
		transition: color 0.2s ease-out;

		&:hover{
			color: #b3232b;
		}
	}
	&>ul>li.current-menu-item>a,
	&>ul>li.current-page-ancestor>a{
		color: #b3232b;
	}

}

#secondarynav {
	padding: 16px 30px;

	&>ul>li>a{
		color: #000;
		text-transform: uppercase;
		font-size: 18px;
		font-family: "SBLiquidW01-Rounded", Helvetica, sans-serif;
		transition: color 0.2s ease-out;

		&:hover{
			color: #b3232b;
		}
	}

	li.current_page_item>a,
	li.current_page_parent>a{
		color: #b3232b;
	}

	&>ul>li>ul{
		display: none;
		margin: 10px 0;

		&>li{
			margin: 5px 0;
		}
	}
		&>ul>li.current_page_item>ul,
		&>ul>li.current_page_parent>ul{
			display: block;
		}
	&>ul>li>ul>li>a{
		color: #000;
		text-transform: uppercase;
		font-size: 12px;
		font-family: "SBLiquidW01-Rounded", Helvetica, sans-serif;
		transition: color 0.2s ease-out;

		&:hover{
			color: #b3232b;
		}
	}
}





.entry-content img{
	margin-top: 10px;
}
.entry-content .spaceup{
	margin-top: 30px;
}

#util {
	top: 5px;
	right: 5px;

	li { float: left; }

	a:hover{
		color: red; 
	}

	/* 
		svg icon stylings. 
		These are only implemented in the wordpress header file currently.
		They require the /fonts folder
	*/
	a.icon-link{
		display: block;
		width:20px;
		height:20px;
		transition: width .5s, height .5s;
	}

	.icon-link:hover{
		fill: red; 
	}

	svg{
	  max-width: 100%;
	  height:100%;
	}

	
}
	

#social{
	@include position(absolute, 10px 10px auto auto);

	li{
		list-style: none;
	}

	a{
		color: #000;
		font-size: 14px;
		line-height: 1;

		&:before{
			content:"";
			display: inline-block;
			background-color: red;
			margin-right: 5px;
			width: 18px;
			height: 18px;
			position: relative;
			bottom: -3px;
		}
	}

	.social-facebook:before{
		background: url(../img/facebook-black.png) no-repeat;
	}
}


/*******************************************
Content
********************************************/
#main { 
	position: relative; 
	left: 415px;
	top: 70px;
	max-width: 590px;
}
    
.m-w1 { 
	
	

	&:before,
	&:after{
		content:"";
		display: block;
		height: 25px;		
	}

	&:before{
		background: url(../img/content-up-bg.png) no-repeat top center;
	}
	
	&:after{
		background: url(../img/content-down-bg.png) no-repeat top center;
	}

	// @include outer-container;
}

 
#content {
	background: rgba(255, 255, 255, 0.65);
	padding: 20px;
	// @include clearfix;
	// //3 column
	// @include span-columns(6);
 // 	@include shift(3);
	// //2 column
	// @include media($large-screen-down) {
	// 	@include span-columns(9);
 // 		@include shift(3);
 // 		float:right; 
 // 		margin:0px;
	// }
	// //1 column
	// @include media($medium-screen-down) {
	// 	@include span-columns(12);
	// 	@include shift(0);
	// } 
}

/* Subnav appears after content-body in markup, 
	but often is shifted to the left */
#subnav {
	 
	// //3 column
	// @include span-columns(3);
	// 	@include shift(-9);
	// //2 column
	// @include media($large-screen-down) {
	// 	@include span-columns(3);
	// 	@include shift(-8);
	// 	margin:0px;
	// }
	// //1 column
	// @include media($medium-screen-down) {
	// 	@include span-columns(12);
	// 	@include shift(0);
	// }
}

#ctas {
	
	@include span-columns(3);
	
	@include media($large-screen-down) {
		@include span-columns(4);
		margin:0px;
	}
	@include media($medium-screen-down) {
		@include span-columns(12);
	}
	
}
 
/*******************************************
Footer
********************************************/
#footer { 
	clear:both;
	font-size: 11px;
}

/* STICKY FOOTERS: Uncomment to enable */
/*
html,body {	height:100%; }
#wrapper { min-height: 100%; position: relative; }
#footer { 
	position: absolute; bottom: 0; left: 0; width: 100%; 
	height: 60px; 
}
#main { padding-bottom: 60px; }
*/
/* END STICKY FOOTERS */


/*******************************************
Page Template Locations Overview
********************************************/
.m-w1.remove-bg {
	&:before,
	&:after {
		display: none;
	}
	#content {
		background: none;
	}
}

.abc-scroll{
	height: 480px;
	overflow-x: hidden;
	overflow-y: auto;
}


.cmap {
	width: 490px;
	height: 445px;
	position: relative;
	top: -60px;
	left: -40px;
	background: url(../img/blankShadow.png) no-repeat left top;
}

.cmap_container{
	width: 322px;
	height: 292px;
	position: relative;
	overflow: hidden;
	left: 61px;
	top: 79px;
}

.cmap_img{
	width: 322px;
	height: 1460px;
	position: absolute;
	bottom: 0%;
	left: 0px;
	background: url(../img/highlights.png) no-repeat;

	&.bc-lower-mainland {
	  bottom: -100%;
	}
	&.bc-interior {
	  bottom: -200%;
	}
	&.bc-vancouver-island {
	  bottom: -300%;
	}
	&.alberta {
	  bottom: -400%;
	}
}


.red-button{
	display: -moz-inline-stack;
	display: inline-block;
	zoom: 1;
	*display: inline;
	color: #fff;
	text-decoration: none;
	padding: 4px 8px;
	margin-top: 10px;
	font-size: 12px;
	background-color: #b3232b;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
	border: none;
}



/*******************************************
Contact Page & Form
********************************************/

#content{

	.gform_footer{
		padding:0;
		margin:0;
	}

	.gform_button{
		display: -moz-inline-stack;
		display: inline-block;
		zoom: 1;
		display: inline;
		color: #fff;
		text-decoration: none;
		padding: 4px 8px;
		margin-top: 10px;
		font-size: 12px;
		background-color: #b3232b;
		-webkit-border-radius: 10px;
		-moz-border-radius: 10px;
		border-radius: 10px;
		border: none;
	}

}  


.contact-info{
	padding: 0px 30px 15px 30px;
	margin-top:-20px;

	h2{
		font-size: 18px;
		font-family: "SBLiquidW01-Rounded", Helvetica, sans-serif;
		margin:10px 0;
		text-transform: uppercase;

		.red{
			color: #b3232b;
			text-transform: none;
		}

		.larger{font-size:16px;}

	}

	p{
		font-family: "SBLiquidW01-Rounded", Helvetica, sans-serif;
	    margin: 10px;
	    text-transform: uppercase;
	    margin:10px 0;
	    font-size: 14px;
	    line-height: 22px;
	}
}



/*******************************************
Menu Template
********************************************/

.page-template-menu {

	#main{
		position: absolute;
		left:501px;
		top:55px;
		width:443px;
	}

	.m-w1:before, .m-w1:after{display:none;}

	#content{
		padding:51px;
		background: none;
	}

	.c-w1{
		// padding: 20px;
		// overflow: auto;
		// max-height: 518px;
	}

	.abc-scroll{
		height: 518px;
	}

	.entry-content{
		padding: 20px;
	}

}