/*
*
* Accordion Styling. 
* Currently set up for Wordpress Flexible Content ACF fields 
**************************************************************/

.accordion{
	margin:20px 0;
	
	.accordion-item{
		width:100%;
		padding:0px;
		display: block;

	}

	.accordion-title{
		background:$accordion-color;
		color:#fff;
		display: block;
		height:50px;
		line-height: 30px;
		font-size:16px;
		font-size:1rem;
		padding:10px 15px;
		margin:0;
		cursor: pointer;
		position: relative;
		border-bottom:1px solid darken( $accordion-color, 10% );

		&:after{
			content:'\2b';
			display: block;
			position: absolute;
			right:20px;
			top:20px;
			line-height: 1em;
			width:16px;
			height:16px;
			background:#fff;
			color:$accordion-color;
			-webkit-border-radius:50%;
			border-radius:50%;
			text-align: center;
			font-family: 'Verdana'; 
			font-size:14px;
			font-weight: bold;
		}

		&.open:after{
			content:'\2212';
		}
	}

	.inner{
		max-height: 0px;
		overflow: hidden;
		padding:0 20px;
		transition:all .5s;
	
		&[aria-hidden='false']{
			max-height:2000px;
			padding:20px;
		}
	}
}

		