table {
  border-collapse: collapse;
  font-feature-settings: "kern", "liga", "tnum";
  margin: $small-spacing 0;
  table-layout: fixed;
  width: 100%;
}

th {
  border-bottom: 1px solid shade($base-border-color, 25%);
  font-weight: 600;
  padding: $small-spacing 0;
  text-align: left;
}

td {
  border-bottom: $base-border;
  padding: $small-spacing 0;
}

tr,
td,
th {
  vertical-align: middle;
}


// table{
//   margin:30px 0;
//   @include font-size(1.5);
//   line-height: 1.73em;
//   border-top:5px solid $prim-colour;

//   tr{
//     border-bottom:1px solid $prim-colour;
//   } 
//   th{
//     padding:10px;
//     text-transform: uppercase;
//     font-weight: 700;
//   }
//   td{
//     padding:10px;
//   }

//   caption{
//     text-transform: uppercase;
//     color:$prim-colour;
//     @include font-size(1.8);
//     font-weight: 700;
//     padding-bottom:10px;

//   }

// }

// .table-row{
//   margin-bottom: 20px;
//   clear: both;
//   margin: 0 0 10px;
//   overflow: hidden;
//   padding: 1px;
// }

// .table-row > label, legend{
//   float: left;
//   padding-right: 25px;
//   width: 25%;
//  }


// form > div > div, form > div > fieldset > div {
//   float: right;
//   width: 75%;
//   line-height: 25px;
// }