/*
*
* Tabs Styling. Switches to accordion for mobile
* Currently set up for Wordpress Flexible Content ACF fields 
**************************************************************/

.tabs{
	margin:20px 0;
	
	ul.tab-header{
		display: none;
		margin:0px;
		@include media($medium-screen-up) {
			border-left:1px solid $tabBorder;
			border-left:1px solid $tabBorder;
			width:100%;
			display: block;
			height:50px;
		}

		li{
			list-style: none; 
			float:left;
			display: block;
			border-top:1px solid $tabBorder;
			border-right:1px solid $tabBorder;
			height:50px;
			line-height: 30px;
			font-size:20px;
			font-size:1.25rem;
			padding:10px 20px;
			text-decoration: none;
			color:$tabActive;
			cursor: pointer;
			
			&[aria-selected='true']{
				background:$tabActive;
				color:#fff;
			}
			&:first-child{
				border-left:none
			}
		}		
	}

	.tab-content{
		margin:20px 0;
		border:1px solid $tabBorder;

		@include media($medium-screen-up) {
			margin:0
		}
	}

	
	.tab-box{
		display:block;
		@include media($medium-screen-up) {
			clear:both;
			padding:20px;
		}

		&[aria-hidden='true']{
			@include media($medium-screen-up) {
				display: none;
			}
		} 

		.inner{
			max-height: 0px;
			overflow: hidden;
			padding:0 20px;
			transition:all .5s;
			@include media($medium-screen-up) {
				max-height: none;
			}

			&[aria-hidden='false']{
				max-height:2000px;
				padding:20px;
			}
		}
	}

	/*** title for mobile accordion **/
	.tab-title{
		background:$tabActive;
		color:#fff;
		display: block;
		height:50px;
		line-height: 30px;
		padding:10px 15px;
		cursor: pointer;
		position: relative;
		border-bottom:1px solid darken( $accordion-color, 10% );
		@include media($medium-screen-up) {
			display: none;
		}

		&:after{
			content:'\2b';
			display: block;
			position: absolute;
			right:20px;
			top:20px;
			line-height: 1em;
			width:16px;
			height:16px;
			background:#fff;
			color:$accordion-color;
			-webkit-border-radius:50%;
			border-radius:50%;
			text-align: center;
			font-family: 'Verdana'; 
			font-size:14px;
			font-weight: bold;
		}

		&.open:after{
			content:'\2212';
		}
	} 
}


	