ul,
ol {
  list-style-type: none;
  margin: 0;
  padding: 0;

  &%default-ul {
    list-style-type: disc;
    margin-bottom: $small-spacing;
    padding-left: $base-spacing;
  }

  &%default-ol {
    list-style-type: decimal;
    margin-bottom: $small-spacing;
    padding-left: $base-spacing;
  }
}

dl {
  margin-bottom: $small-spacing;

  dt {
    font-weight: bold;
    margin-top: $small-spacing;
  }

  dd {
    margin: 0;
  }
}

.c-w1{
  ol{
    list-style: decimal outside;
    margin: 0.25em 0 1em 2em;
    ol{
      margin-bottom:0px;
    }
  }

  ul{
    list-style: disc outside;
    margin: 0.25em 0 1em 2em;

    ul{
      margin-bottom:0;
    }
  }
}

/*doc
---
title: Lists
name: lists
category: Base CSS
---

These list styles are only applied in the content area. They are targeted
using the .c-w1 class.

<div class='c-w1'>
<ul>
  <li>List Item</li>
  <li>List Item</li>
  <li>List Item
      <ul>
        <li>List Item</li>
        <li>List Item</li>
        <li>List Item</li>
      </ul>
  </li>
  <li>List Item</li>
  <li>List Item</li>
</ul>

<ol>
  <li>List Item</li>
  <li>List Item</li>
  <li>List Item
      <ol>
        <li>List Item</li>
        <li>List Item</li>
        <li>List Item</li>
      </ol>
  </li>
  <li>List Item</li>
  <li>List Item</li>
</ol>
</div>

*/