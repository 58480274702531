// // Returns rem and px
// // Note: Assumes html font size 62.5%
// @mixin font-size($sizeValue: 1.6) {
//   font-size: ($sizeValue * 10) + px;
//   font-size: $sizeValue + rem;
// }

@mixin elementInvisible(){
  position: absolute !important;
  height: 1px; width: 1px; 
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}

@mixin border(){
	border:1px solid #ccc;
} 
