/* -------------------------------------------------------------- 
   
   Basic WordPress Blog Styles   
     
-------------------------------------------------------------- */   


/* Images
-------------------------------------------------------------- */

/*doc
---
title: Content Images
name: content_images
category: Content Classes
---

                                                        

<div class='entry-content'>
	<h3>Image class=alignleft</h3>
	<img src='./images/sample.jpg' class='alignleft' />                
	<p>Mlkshk Williamsburg +1, swag flannel retro yr put a bird on it 
	disrupt street art keffiyeh selvage wolf trust fund farm-to-table. 
	DIY tousled pour-over tattooed, occupy plaid lomo McSweeney's Bushwick 90's. 
	Leggings Intelligentsia sartorial drinking vinegar, meh vegan umami migas Etsy bespoke.
	 Occupy Banksy salvia ethical, Helvetica Blue Bottle McSweeney's DIY. 
	 Try-hard plaid PBR Helvetica, Wes Anderson keytar drinking vinegar put a bird on it 
	 hashtag 3 wolf moon meggings Vice Banksy viral. Keffiyeh post-ironic cornhole
	  single-origin coffee readymade fanny pack. Organic listicle quinoa skateboard 
	  squid cliche flannel dreamcatcher.</p>


	<h3>Image class=alignright</h3>
	<img src='./images/sample.jpg' class='alignright' />
	<p>Mlkshk Williamsburg +1, swag flannel retro yr put a bird on it 
	disrupt street art keffiyeh selvage wolf trust fund farm-to-table. 
	DIY tousled pour-over tattooed, occupy plaid lomo McSweeney's Bushwick 90's. 
	Leggings Intelligentsia sartorial drinking vinegar, meh vegan umami migas Etsy bespoke.
	 Occupy Banksy salvia ethical, Helvetica Blue Bottle McSweeney's DIY. 
	 Try-hard plaid PBR Helvetica, Wes Anderson keytar drinking vinegar put a bird on it 
	 hashtag 3 wolf moon meggings Vice Banksy viral. Keffiyeh post-ironic cornhole
	  single-origin coffee readymade fanny pack. Organic listicle quinoa skateboard 
	  squid cliche flannel dreamcatcher.</p>

	<h3>Image class=aligncenter</h3>
	<img src='./images/sample.jpg' class='aligncenter' />
	<p>Mlkshk Williamsburg +1, swag flannel retro yr put a bird on it 
	disrupt street art keffiyeh selvage wolf trust fund farm-to-table. 
	DIY tousled pour-over tattooed, occupy plaid lomo McSweeney's Bushwick 90's. 
	Leggings Intelligentsia sartorial drinking vinegar, meh vegan umami migas Etsy bespoke.
	 Occupy Banksy salvia ethical, Helvetica Blue Bottle McSweeney's DIY. 
	 Try-hard plaid PBR Helvetica, Wes Anderson keytar drinking vinegar put a bird on it 
	 hashtag 3 wolf moon meggings Vice Banksy viral. Keffiyeh post-ironic cornhole
	  single-origin coffee readymade fanny pack. Organic listicle quinoa skateboard 
	  squid cliche flannel dreamcatcher.</p>
</div>

*/

.entry-content img {
	margin-bottom:1em;
}
.alignleft {
	display: inline;
	float: left;
    margin-right: 1.5em;
    @include media($medium-screen-down) {
    	float:none;
    	clear: both;
	    display: block;
    	margin:1em auto;
    }
}
.alignright {
	display: inline;
	float: right;
    margin-left: 1.5em;
    @include media($medium-screen-down) {
    	float:none;
    	clear: both;
	    display: block;
    	margin:1em auto;
    }
}
.aligncenter {
	clear: both;
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.wp-caption {
	text-align: center;
	margin-bottom: 1.5em;
}
.wp-caption img {
	border: 0 none;
	margin: 0;
	padding: 0;
}
.wp-caption p.wp-caption-text {
	margin: 0;
}
.wp-smiley { 
	max-height: 1em;
	margin:0 !important;
}
.gallery dl {
	margin: 0;
}
.gallery-caption {
	margin:-1.5em 0 0 0;
}


/* Pullquotes
-------------------------------------------------------------- */

blockquote.left {
	float: left;
	margin-left: 0;
	margin-right: 20px;
	text-align: right;
	width: 33%;
}
blockquote.right {
	float: right;
	margin-left: 20px;
	margin-right: 0;
	text-align: left;
	width: 33%;
}


/* Text meant only for screen readers. */
.screen-reader-text {
	clip: rect(1px, 1px, 1px, 1px);
	position: absolute !important;
	height: 1px;
	width: 1px;
	overflow: hidden;
}

.screen-reader-text:focus {
	background-color: #f1f1f1;
	border-radius: 3px;
	box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
	clip: auto !important;
	color: #21759b;
	display: block;
	font-size: 14px;
	font-size: 0.875rem;
	font-weight: bold;
	height: auto;
	left: 5px;
	line-height: normal;
	padding: 15px 23px 14px;
	text-decoration: none;
	top: 5px;
	width: auto;
	z-index: 100000; /* Above WP toolbar. */
}

.bypostauthor{
	font-weight:bold;
}

.sticky{}


