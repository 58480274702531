@media print { 
	*,
	*:before,
	*:after { 
		background: transparent !important;
		color: #000 !important;
		box-shadow: none !important;
		text-shadow: none !important;
	}

	a {
		text-decoration: underline;

		&:visited {
			text-decoration: underline;
		}

		&[href]:after {
			content: " (" attr(href) ")";
		}

		&[href^="#"]:after,
		&[href^="javascript:"]:after {
			content: "";
		}

	}
	
	abbr[title]:after {
		content: " (" attr(title) ")";
	}
	
	pre,
	blockquote {
		border: 1px solid #999;
		page-break-inside: avoid;
	}

	thead {
		display: table-header-group;
	} 

	tr,
	img {
		page-break-inside: avoid;
	}

	img {
		max-width: 100% !important;
		height: auto;
	}

	p,
	h2,
	h3 {
		orphans: 3;
		widows: 3;
	}

	h2, h3 {
		page-break-after: avoid;
	}

	//Custom GSSI

	#ctas,
	#subnav,
	#mainnav,
	#header-banner,
	#util,
	#bottomlinks,
	#credit {
		display: none;
	}

	#wrapper {
		width: 7in;
		padding-top: 1in;
		position: relative;
	}

	#header {
		position: absolute;
		left: 0;
		top: 0;
		height: 1in;
	}

	#footer {
		margin-top: 0.5in;
	}

}