@charset "UTF-8";

/// Mixes a color with black.
///
/// @param {Color} $color
///
/// @param {Number (Percentage)} $percent
///   The amount of black to be mixed in.
///
/// @example scss - Usage
///   .element {
///     background-color: shade(#ffbb52, 60%);
///   }
///
/// @example css - CSS Output
///   .element {
///     background-color: #664a20;
///   }
///
/// @return {Color}

@function shade($color, $percent) {
  @return mix(#000, $color, $percent);
}
