/*************************************
Overview
**************************************/

/*doc
---
title: Overview Items
name: overview_items
category: content_classes
---



<ul class='overview-list'>
	<li>
		<a href='#'>
		<img src='./images/sample.jpg' alt='' />
		<h2>Overview Item 1</h2>
		</a>
	</li>
    <li>
		<a href='#'>
		<img src='./images/sample.jpg' alt='' />
		<h2>Overview Item 1</h2>
		</a>
	</li>
	<li>
		<a href='#'>
		<img src='./images/sample.jpg' alt='' />
		<h2>Overview Item 1</h2>
		</a>
	</li>
	<li>
		<a href='#'>
		<img src='./images/sample.jpg' alt='' />
		<h2>Overview Item 1</h2>
		</a>
	</li>
	<li>
		<a href='#'>
		<img src='./images/sample.jpg' alt='' />
		<h2>Overview Item 1</h2>
		</a>
	</li>
	<li>
		<a href='#'>
		<img src='./images/sample.jpg' alt='' />
		<h2>Overview Item 1</h2>
		</a>
	</li>
	<li>
		<a href='#'>
		<img src='./images/sample.jpg' alt='' />
		<h2>Overview Item 1</h2>
		</a>
	</li>
</ul>

*/

ul.overview-list{
	margin:0px;
	padding:0px;

	li{
		display: inline-block;
		vertical-align: top;
		width:20%;
		margin-right:5%;
		margin-bottom: 20px;

		@include media($medium-screen-down) {
			width:45%;
			margin-right:5%;
		}
	
		&:nth-child(4n){
			margin-right:0px;
		}

		&:nth-child(2n){
			@include media($medium-screen-down) {
				margin-right:0px;
			}
		}

		a {text-decoration: none;}

		h2{font-size:18px;} 
	}
}